// Variables
$primary-color: #FFEDA5;
$secondary-color: #fff200;
$text-color: #333;
$bg-color: #f3f4f6;
$white: #fff;

// Mixins
// @mixin respond($breakpoint) {
//     @if $breakpoint =='phone' {
//         @media (max-width: 767px) {
//             @content;
//         }
//     }

//     @if $breakpoint =='tablet' {
//         @media (min-width: 768px) and (max-width: 1023px) {
//             @content;
//         }
//     }

//     @if $breakpoint =='desktop' {
//         @media (min-width: 1024px) {
//             @content;
//         }
//     }
// }

//!For scrollbar Effect

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.header_menu_content {
    background-color: var(--Header-header_menu_container--background);
    color: var(--Header-header_menu_container--color);
}

.main_dashboard {
    margin: 0 auto;
    background-color: var(--HiveMedical-main_dashboard--background);
    width: 100%;
    min-height: 100vh;
    height: 100%;

    // @include respond('tablet') {
    //     // max-width: 768px;
    // }

    // @include respond('desktop') {
    //     // max-width: 1200px;
    // }

    .header_main {

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--Header-header_main--background);
        padding: 1rem;


        .header_logo {
            display: flex;
            align-items: center;
            background: var(--Header-header_logo--background);

            img {
                height: 50px;
                margin-right: 1rem;
            }

            span {
                font-size: 1.5rem;
                font-weight: bold;
            }
        }

        .header_menu {
            display: inline-block;
            cursor: pointer;

            img {
                width: 20px;
                display: block;
            }
        }
    }


    .hive_medical_main {
        padding: 2rem;

        @media (max-width: 767px) {
            padding: 1rem;
        }

        .hive_medical_section_title {
            font-size: var(--Common-hive_medical_section_label--font-size);
            color: var(--Common-hive_medical_section_title--color);
            font-style: var(--Common-hive_medical_section_title--font-style);
            font-family: var(--Common-hive_medical_section_title--font-family);

        }

        .hive_medical_section_label,
        .ul_main_list .custom_bullets {
            font-size: var(--Common-hive_medical_section_label--font-size);
            color: var(--Common-hive_medical_section_label--color);
            font-style: var(--Common-hive_medical_section_label--font-style);
            font-family: var(--Common-hive_medical_section_label--font-family);
        }

        .hive_medical_sub_title_label {
            font-size: var(--YourTimeline-hive_medical_sub_title_label--font-size);
            color: var(--YourTimeline-hive_medical_sub_title_label--color);
            font-style: var(--YourTimeline-hive_medical_sub_title_label--font-style);
            font-family: var(--YourTimeline-hive_medical_sub_title_label--font-family);

        }

        .slider_text_overflow {

            display: -webkit-box;
            word-break: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: Vertical;
            overflow: hidden;
        }


        .hive_medical_section {
            background-color: var(--Common-hive_medical_section--background);


            .descriptions_section {
                max-height: 400px;
                min-height: 150px;
                max-width: 470px;
                background-color: var(--Common-descriptions_section--background);
                border-radius: 8px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                padding: 1.5rem;
                margin-bottom: 1rem;
                overflow-y: auto;
                position: relative;

                .descriptions_section_label {
                    font-size: var(--Common-descriptions_section_label--font-size);
                    color: var(--Common-descriptions_section_label--color);
                    font-style: var(--Common-descriptions_section_label--font-style);
                    font-family: var(--Common-descriptions_section_label--font-family);
                }

                .descriptions_section_label_required {
                    color: var(--Common-descriptions_section_label_required--Color);
                }

                .descriptions_section_card_container {
                    background: var(--Common-descriptions_section_card_container--background);
                    max-width: calc(var(--Common-descriptions_section_label--font-size) * 10);

                    .hive_medical__button {
                        margin: 10px 0 0;
                    }
                }

                s .VideoUrl {
                    background-color: #f9f9f9;
                    /* Light background color for contrast */
                    border-radius: 8px;
                    /* Rounded corners */
                    padding: 12px;
                    /* Space inside the container */
                    margin-top: 16px;
                    /* Space above the container */
                    margin-bottom: 16px;
                    /* Space below the container */
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    /* Subtle shadow for depth */
                    display: flex;
                    flex-direction: column;
                }

                .VideoUrl label {
                    font-size: 16px;
                    /* Font size for label */
                    font-weight: 600;
                    /* Bold text for label */
                    color: #333;
                    /* Dark color for readability */
                    margin-bottom: 8px;
                    /* Space below the label */
                }

                .VideoUrl p {
                    font-size: 14px;
                    /* Font size for the link */
                    color: #0066cc;
                    /* Color for the link */
                    text-decoration: underline;
                    /* Underline the link */
                    margin: 0;
                    /* Remove default margin */
                    cursor: pointer;
                    /* Change cursor to pointer on hover */
                    word-wrap: break-word
                }

                .VideoUrl p:hover {
                    color: #004d99;
                    /* Darker color on hover */
                    text-decoration: none;
                    /* Remove underline on hover */
                }
            }



            .radio_group_container {
                background-color: var(--ToDoNow-radio_group_container--background);
                color: var(--ToDoNow-radio_group_container--checked-color);
                border: var(--ToDoNow-radio_group_container--border-width) solid var(--ToDoNow-radio_group_container--background);
                min-width: 26px;
                height: 26px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 22px;
                    height: 22px;
                    min-width: 22px;
                }
            }

            .sidler_card {
                background: var(--YourTimeline-sidler_card--background);
                max-width: calc(var(--Common-hive_medical_section_label--font-size) * 10);

                // width: calc(var(--Common-hive_medical_section_label--font-size) * 10);
                /* Calculate max-width based on font-size variable */
                .hive_medical__button {
                    margin: 10px 0 0;
                }
            }

            .sidler_card_documents {
                background: white;
                max-width: calc(var(--Common-hive_medical_section_label--font-size) * 10);

                // width: calc(var(--Common-hive_medical_section_label--font-size) * 10);
                /* Calculate max-width based on font-size variable */
                .hive_medical__button {
                    margin: 10px 0 0;
                }
            }

            //! Schedule Welcome Call
            .schedule_button {
                .schedule_section-button {
                    width: auto;
                    padding: 13px;
                    margin: 10px 0 0;
                    // font-size: var(--CommonAuthLayout-auth_card_button--font-size);
                    // color: var(--CommonAuthLayout-auth_card_button--color);
                    // font-family: var(--CommonAuthLayout-auth_card_button--font-family);
                }
            }

            .hive_medical_section-button {
                display: block;
                margin: 0 auto;
                background-color: $secondary-color;
                border: none;
                padding: 0.5rem 1rem;
                border-radius: 25px;
                cursor: pointer;
            }

            .hive_medical__timeline-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;

                .hive_medical__timeline-item-title {
                    font-weight: bold;
                }
            }

            .custom_bullets::marker {
                color: black;
                font-size: 25px;

            }

            .no_data {
                top: 50%;
                left: 50%;
            }
        }
    }


    .costume_Toter {
        display: var(--costume_Toter--massage);

    }
}


//!For Custom Button
.hive_medical__button {
    color: var(--HiveMedical-hive_medical__button--color);
    font-size: var(--HiveMedical-hive_medical__button--font-size);
    font-family: var(--HiveMedical-hive_medical__button--font-family);
    background-color: var(--HiveMedical-hive_medical__button--background-color);

    &:hover {
        color: var(--HiveMedical-hive_medical__button--hover-color);
        background-color: var(--HiveMedical-hive_medical__button--hover-background-color);
    }

}

.custom_bullets {
    display: flex;

    &:before {
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        flex-shrink: 0;
        margin-right: 15px;
        background: #000000;
        position: relative;
        top: 8px;
        border-radius: 50%;
    }


}


.video_player_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    /* Ensure it's above other content but below any other high-priority elements */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .overly {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 74%);
        /* Semi-transparent dark overlay */
        z-index: -1;
        /* Place it behind the video player */
    }

    .basic_video_player {
        position: relative;
        max-width: 800px;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        background: #000;
        z-index: 1;

        .close_btn {
            background: rgba(19, 18, 18, 0.411);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -3px;
            right: -37px;
            color: white;
            cursor: pointer;
            z-index: 2;
            font-size: 18px;
            line-height: 1;
        }
    }
}

//!this for Model DialogBox
.DialogBoxModel {
    background-color: var(--Common-DialogBoxModel--background);
    color: var(--Common-DialogBoxModel--color);
    font-family: var(--Common-DialogBoxModel--font-family);
    font-style: var(--Common-DialogBoxModel--font-style);
    border-color: var(--Common-DialogBoxModel--border-color);

    input,
    textarea {
        color: var(--Common-DialogBoxModel--input-text-color);

        &::placeholder {
            color: var(--Common-DialogBoxModel--placeholder-color);
            opacity: 1; // Ensures full color opacity on the placeholder
        }
    }

    .DialogBoxModel_date_picker {
        background-color: var(--Common-DialogBoxModel--background);
        color: var(--Common-DialogBoxModel--color);

        .DialogBoxModel_date_picker_placeholder {
            color: var(--Common-DialogBoxModel--placeholder-color);
            opacity: 1; // Ensures full color opacity on the placeholder
        }

        &:hover {
            background-color: var(--Common-DialogBoxModel--background);
            color: var(--Common-DialogBoxModel--color);
        }

    }

    .DialogBoxModel_error_message {
        color: var(--Common-DialogBoxModel_input_error_message--color);
    }
}

/* Base calendar styling */
.DialogBoxModel_date_picker_calendar {
    background-color: var(--Common-DialogBoxModel_date_picker_calendar--background);
    /* Calendar background color */
    color: var(--Common-DialogBoxModel_date_picker_calendar--color);
    /* Text color */
    border-radius: 8px;
    /* Rounded corners */
    padding: 16px;
    /* Padding around the calendar */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    /* Box shadow for subtle depth */

}

.schedule_section-button {
    white-space: normal;
    padding: 10px;
    line-height: 1.2;
    max-width: 200px;
    width: 100%;
    height: auto;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;
    font-size: 14px;

    @media (max-width: 767px) {
        font-size: 12px;
        padding: 8px;
        min-height: 40px;
    }
}