$error: #f4516c;

:root {
    --auth_page_background_image: "https://contents.hivebariatrics.com/local/DemoPractice-TESTEst-Prasctice/task_design/page_bg_image_1720241885.png";
    --CommonAuthLayout-auth_page_background--background-color: black;

    --CommonAuthLayout-auth_card--backGround: white;
    --CommonAuthLayout-auth_card--border: '';
    --CommonAuthLayout-auth_card--border-radius: 6px;

    --CommonAuthLayout-auth_card--width: 448px;
    --CommonAuthLayout-auth_card_title--font-size: 16px;
    --CommonAuthLayout-auth_card--title-color: rgb(24, 20, 20);
    --CommonAuthLayout-auth_card--title-font-family: ;
    --CommonAuthLayout-auth_card--title-font-style: ;


    --SelectScreen-selectScreen_checkbox_label--font-size: 16px;
    --SelectScreen-selectScreen_checkbox--background: white;
    --SelectScreen-selectScreen_checkbox--color: rgb(223, 40, 40);

    --CommonAuthLayout-Auth_card_error--Color: rgb(12, 11, 11);

    --CommonAuthLayout-Auth_card_input--background_color: rgb(255, 255, 255);
    --CommonAuthLayout-Auth_card_input_placeholder--color: black;
    --CommonAuthLayout-Auth_card_input--font-family: ;
    --CommonAuthLayout-Auth_card_input--font-style: ;
    --CommonAuthLayout-Auth_card_input_placeholder--font-family: ;
    --CommonAuthLayout-Auth_card_input_placeholder--font-style: ;

    --CommonAuthLayout-auth_card_button--background-color: #FFA91E;
    --CommonAuthLayout-auth_card_button--hover-background-color: rgb(202 138 4);
    --CommonAuthLayout-auth_card_button--color: black;
    --CommonAuthLayout-auth_card_button--hover-color: black;
    --CommonAuthLayout-auth_card_button--font-size: 14px;
    --CommonAuthLayout-auth_card_button--font-style: ;
    --CommonAuthLayout-auth_card_button--font-family: ;
}