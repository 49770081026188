.auth_page_background {
    background-color: var(--CommonAuthLayout-auth_page_background--background-color);
    // background-image: url("../images/background.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

/* Custom CSS for the background and the logo */

.auth_card {
    width: var(--CommonAuthLayout-auth_card--width);
    background-color: var(--CommonAuthLayout-auth_card--backGround);
    border: var(--CommonAuthLayout-auth_card--border);
    border-radius: var(--CommonAuthLayout-auth_card--border-radius);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .auth_card_error {
        color: var(--CommonAuthLayout-Auth_card_error--Color);
    }

    .auth_card_input {
        background-color: var(--CommonAuthLayout-Auth_card_input--background_color);
        font-family: var(--CommonAuthLayout-Auth_card_input--font-family);
        font-style: var(--CommonAuthLayout-Auth_card_input--font-style);
    }

    .input_placeholder {
        &::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            font-family: var(--CommonAuthLayout-Auth_card_input_placeholder--font-family);
            font-style: var(--CommonAuthLayout-Auth_card_input_placeholder--font-style);
            color: var(--CommonAuthLayout-Auth_card_input_placeholder--color);
        }
    }

    .auth_card_title {
        font-size: var(--CommonAuthLayout-auth_card_title--font-size);
        color: var(--CommonAuthLayout-auth_card--title-color);
        font-family: var(--CommonAuthLayout-auth_card--title-font-family);
        font-style: var(--CommonAuthLayout-auth_card--title-font-style);
        font-weight: var(--CommonAuthLayout-auth_card_title--font-weight);
    }

    .auth_card_Button {
        background-color: var(--CommonAuthLayout-auth_card_button--background-color);
        font-size: var(--CommonAuthLayout-auth_card_button--font-size);
        color: var(--CommonAuthLayout-auth_card_button--color);
        font-family: var(--CommonAuthLayout-auth_card_button--font-family);

        font-style: var(--CommonAuthLayout-auth_card_button--font-style);
        line-height: 22px;
        min-height: unset;
        height: auto;
        font-weight: 400;

        &:hover {
            background-color: var(--CommonAuthLayout-auth_card_button--hover-background-color);
            color: var(--CommonAuthLayout-auth_card_button--hover-color);

        }
    }

    .selectScreen {
        .selectScreen_checkbox {
            background: var(--SelectScreen-selectScreen_checkbox--background);
            color: var(--SelectScreen-selectScreen_checkbox--color);
            border-radius: 10px;
        }

        .selectScreen_checkbox_label {
            font-size: var(--SelectScreen-selectScreen_checkbox_label--font-size);
            margin-left: 8px;
        }
    }


}

.auth-logo-container {
    max-height: 170px;
    max-width: 178px;
    display: inline;
    display: flex;


    .auth-logo {
        // max-width: 70px;
        // width: 100%;
        // height: auto;
        padding: 25px 0 0;
        margin: 0 auto;
    }
}


.vision_icon {
    // @apply absolute top-[6px] right-[10px];
    position: absolute;
    top: 6px;
    right: 10px;

    img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
}

.phone_input {
    input {
        width: 100% !important;
    }
}

.alert-box {
    position: relative;
    width: calc(100% - 40px);
    max-width: 350px;
    border: 1px solid var(--CommonAuthLayout-Auth_card_error--Color);
    border-radius: 4px;
    padding: 9px 30px 9px 15px;
    margin: 0 auto 14px;


    .close {
        position: absolute;
        width: 20px;
        min-width: 20px;
        top: 10px;
        right: 5px;
        height: 20px;
        color: var(--CommonAuthLayout-Auth_card_error--Color);
        cursor: pointer;
    }

    .text {
        color: var(--CommonAuthLayout-Auth_card_error--Color);
        font-weight: 400;
        font-size: 14px;
        text-align: justify;
    }
}

.cursor {
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    /* Ensures the cursor doesn't interfere with other elements */
    z-index: 1000;
    transition: transform 0.1s ease-out;
    /* Smooth movement */
}

.react-tel-input.phone_input {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;

    .form-control.auth_card_input {
        border: none;
        border-bottom: 1px solid #707070;
        border-radius: 0px;
        position: unset;
        padding: 0 0 0 20px;
        flex: 1;
        background-color: var(--CommonAuthLayout-Auth_card_input--background_color);
        font-family: var(--CommonAuthLayout-Auth_card_input--font-family);
        font-style: var(--CommonAuthLayout-Auth_card_input--font-style);
    }


    .flag-dropdown.auth_card_input {
        width: 35px;
        height: 35px;
        background-color: var(--CommonAuthLayout-Auth_card_input--background_color);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        position: unset;
        border-bottom: 1px solid #707070;
        border-radius: 0;
        margin-right: 0px;
        position: relative;

        .selected-flag {
            padding: 0;
            width: 16px;
            height: 14px;
        }

        &:before {
            content: "";
            right: -5px;
            position: absolute;
            height: 20px;
            width: 1px;
            background: #707070;
            top: 0;
            bottom: 0;
            margin: auto;

        }
    }
}