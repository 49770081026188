.not_found {
    background-color: rgb(41, 41, 37);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    #container {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        svg {
            width: 500px;
            height: 400px;

            // fill: #16a085;
            .st0 {
                font-family: 'FootlightMTLight';
            }

            .st1 {
                font-size: 83.0285px;
            }

            .st2 {
                fill: gray;
            }

            path#XMLID_5_ {
                // fill: #16a085;
                filter: url(#blurFilter4);
            }

            path#XMLID_11_,
            path#XMLID_2_ {
                // fill: #16a085;
            }

            .circle {
                animation: out 2s infinite ease-out;
                // fill: #16a085;
            }
        }

        .message {
            color: #FFA91E;

            &::after {
                content: "]";
            }

            &::before {
                content: "[";
            }

            &::after,
            &::before {
                color: #FFA91E;
                font-size: 20px;
                animation: opacity 2s infinite;
                margin: 0 50px;
            }
        }
    }
}

@keyframes opacity {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes out {
    0% {
        r: 1;
        opacity: 0.9;
    }

    25% {
        r: 5;
        opacity: 0.3;
    }

    50% {
        r: 10;
        opacity: 0.2;
    }

    75% {
        r: 15;
        opacity: 0.1;
    }

    100% {
        r: 20;
        opacity: 0;
    }
}