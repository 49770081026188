// --primary-color: #FFEDA5;
// $secondary-color: #fff200;
// $text-color: #333;
// $bg-color: #f3f4f6;
// $white: #fff;
:root {


    --Header-header_logo--background: ;
    --HiveMedical-main_dashboard--background: #FFEDA5;
    --HiveMedical-hive_medical__button--background-color: #FFA91E;
    --HiveMedical-hive_medical__button--color: #000000;
    --HiveMedical-hive_medical__button--hover-color: #e4e4e4;
    --HiveMedical-hive_medical__button--hover-background-color: #c02222;
    --HiveMedical-hive_medical__button--font-family: ;

    --Header-header_main--background: white;
    --Common-hive_medical_section--background: white;

    --Common-hive_medical_section_title--font-size: 30px;
    --Common-hive_medical_section_title--color: rgb(0, 0, 0);
    --Common-hive_medical_section_title--font-style: normal;

    --YourTimeline-hive_medical_sub_title_label--font-size: 16px;
    --YourTimeline-hive_medical_sub_title_label--color: black;
    --YourTimeline-hive_medical_sub_title_label--font-style: normal;
    --YourTimeline-hive_medical_sub_title_label--font-family: ;

    --Common-hive_medical_section_label--font-size: 20px;
    --Common-hive_medical_section_label--color: rgb(0, 0, 0);
    --Common-hive_medical_section_label--font-style: normal;
    --Common-hive_medical_section_label--font-family: ;

    --ToDoNow-radio_group_container--background: white;
    --ToDoNow-radio_group_container--border-color: rgb(3, 3, 3);
    --ToDoNow-radio_group_container--border-width: 2px;
    --ToDoNow-radio_group_container--checked-color: rgb(0, 0, 0);

    --YourTimeline-sidler_card--background: white;


    //!-----------------------------------------------------Start Not set Css Variable Keys -----------------------------------------------
    //!this for Model DialogBox
    --Common-DialogBoxModel--background: rgb(255, 255, 255);
    --Common-DialogBoxModel--color: rgb(0, 0, 0);
    --Common-DialogBoxModel--font-family: ;
    --Common-DialogBoxModel--font-style: ;
    --CommonAuthLayout-auth_card--border-color: rgb(255, 255, 255);
    --Common-DialogBoxModel--placeholder-color: rgb(0, 0, 0);
    --Common-DialogBoxModel--input-text-color: rgb(5, 5, 5);
    --Common-DialogBoxModel_input_error_message--color: rgb(248, 5, 5);

    //!descriptions_section && cards
    --Common-descriptions_section--background: #ffcd06;
    --Common-descriptions_section_label--font-size: 16px;
    --Common-descriptions_section_label--color: rgb(2, 2, 2);
    --Common-descriptions_section_label--font-style: normal;
    --Common-descriptions_section_label--font-family: ;
    --Common-descriptions_section_label_required--Color: rgb(255, 0, 0);
    --Common-descriptions_section_card_container--background: white;

    --Common-DialogBoxModel_date_picker_calendar--background: rgb(255, 255, 255);
    --Common-DialogBoxModel_date_picker_calendar--color: rgb(0, 0, 0);

    //!Header 
    --Header-header_menu_container--background: rgb(255, 255, 255);
    --Header-header_menu_container--color: rgb(0, 0, 0);
    //!-----------------------------------------------------End Not set Css Variable Keys -----------------------------------------------
}